import { FEATURE_FLAG as CONSENT_FEATURE_FLAG } from '@buzzfeed/consent';
import { isNews } from '../utils/isNews';
import { getUserGeoCountry } from '@buzzfeed/bf-utils/lib/localization';

const featureFlags = [
  {
    name: 'TimeSpentRO_4',
    variations: ['on', 'control'],
    isEligible: () => true,
  },
  {
    name: 'election2020_package_bfdc_bpages',
    variations: ['control', 'on'],
    // all english speaking bpages, except quizzes and shopping posts and not a paid post (Ad)
    isEligible: (buzz) =>
      buzz.isEnglish &&
      !(
        buzz.isQuiz ||
        buzz.isBFPQuiz ||
        buzz.sub_buzzes?.find((sb) => sb.form === 'quiz')
      ) &&
      !buzz.isAd,
  },
  {
    name: 'ads_mweb_quiz_promo-inline1',
    variations: ['control', 'on'],
    isEligible: (buzz) =>
      (buzz.isQuiz ||
        buzz.isBFPQuiz ||
        buzz.sub_buzzes?.find((sb) => sb.form === 'quiz')) &&
      !window.matchMedia('(min-width: 40rem)').matches, // xs
  },
  {
    name: 'PAR-where-to-watch-release',
    variations: ['control', 'on'],
    isEligible: () => true,
  },
  {
    name: 'COMMERCE-1437_gg_category_bpage',
    variations: ['control', 'on'],
    isEligible: (buzz) => buzz.isShopping && buzz.isUS,
  },
  {
    name: 'shoppybot_bpage_promo',
    variations: ['control', 'on'],
    isEligible: (buzz) => buzz.isShopping && buzz.isUS,
  },
  {
    name: 'ads_high_density_bpage_non_quiz',
    variations: ['control', 'on'],
    isEligible: (buzz) =>
      !(
        buzz.isQuiz ||
        buzz.isShopping ||
        buzz.isBFPQuiz ||
        buzz.sub_buzzes?.find((sb) => sb.form === 'quiz')
      ) &&
      buzz.classification.edition !== 'Japan' &&
      !buzz.isAd,
  },
  {
    name: 'ads_high_density_bpage_quiz',
    variations: ['control', 'on'],
    isEligible: (buzz) =>
      (
        buzz.isQuiz ||
        buzz.isBFPQuiz ||
        buzz.sub_buzzes?.find((sb) => sb.form === 'quiz')
      ) &&
      buzz.classification.edition !== 'Japan' &&
      !buzz.isAd,
  },
  {
    name: 'ads_destroy_ads_on_scroll_mobile',
    variations: ['control', 'on'],
    isEligible: (buzz) =>
      buzz.classification.edition !== 'Japan' &&
      !buzz.isAd &&
      !window.matchMedia('(min-width: 40rem)').matches, // xs,
  },
  {
    name: 'ads_shopping_density_test',
    variations: ['control', 'variant1', 'variant2'],
    storeResponse: true,
    isEligible: buzz => buzz.isShopping,
  },
  {
    name: 'comment_signed_out',
    variations: ['control', 'on'],
    isEligible: () => true,
  },
  {
    name: 'ads_wunderkind_uk',
    variations: ['control', 'on'],
    isEligible: (buzz) =>
      (getUserGeoCountry().toLowerCase() === 'gb' ||
      getUserGeoCountry().toLowerCase() === 'ie') && !isNews(buzz) && !buzz.isAd
  },
  {
    name: 'ads_wunderkind_news',
    variations: ['control', 'on'],
    isEligible: (buzz) => isNews(buzz) && !buzz.isAd
  },
  {
    name: 'RT-1041-Outbrain-on-BFN',
    variations: ['control', 'on'],
    isEligible: buzz => isNews(buzz),
  },
  {
    name: 'RT-1042-Admiral-script-on-BFDC',
    variations: ['control', 'on'],
    isEligible: buzz => !isNews(buzz)
  },
  {
    name: 'RT-1042-Admiral-script-on-BFN',
    variations: ['control', 'on'],
    isEligible: buzz => isNews(buzz)
  },
  {
    name: 'RT-1328-Wunderkind-on-desktop',
    variations: ['control', 'on'],
    isEligible: buzz =>  getUserGeoCountry().toLowerCase() === 'us' &&
    !buzz.isShopping && !isNews(buzz) &&
    window.matchMedia('(min-width: 40rem)').matches,
  },
];

const abTests = [
  {
    name: 'commerce_ad_affiliate',
    variations: ['control', 'top', 'buzz'],
    storeResponse: true,
    isEligible: buzz => !buzz.isUS,
  },
  {
    name: 'commerce_ad_recirc',
    variations: ['control', 'top', 'buzz'],
    storeResponse: true,
    isEligible: buzz => buzz.isUS,
  },
  {
    name: 'newsletter-popup',
    variations: ['control', 'on'],
    isEligible: buzz => {
      if (!buzz.isUS) {
        return false;
      }

      if (buzz.tags.includes('amazon-pets')) {
        return true;
      }

      const allSections = buzz.all_classifications
        ? buzz.all_classifications.sections
        : [];
      const allowedSections = ['Shopping'];
      return allSections.some(section => allowedSections.includes(section));
    },
  },
  {
    name: 'newsletter-primeday',
    variations: ['control', 'on'],
    isEligible: buzz => {
      if (!buzz.isUS || buzz.tags.includes('amazon-pets')) {
        return false;
      }
      const allSections = buzz.all_classifications
        ? buzz.all_classifications.sections
        : [];
      const lifestyleSections = [
        'As/Is',
        'Food',
        'Goodful',
        'Nifty',
        'Parents',
        'Shopping',
      ];
      return allSections.some(section => lifestyleSections.includes(section));
    },
  },
  {
    name: 'shoppybot-popup',
    variations: ['control', 'on'],
    isEligible: (buzz) => {
      if (!buzz.isUS) {
        return false;
      }
      const allSections = buzz.all_classifications
        ? buzz.all_classifications.sections
        : [];
      const lifestyleSections = [
        'As/Is',
        'Food',
        'Goodful',
        'Nifty',
        'Parents',
        'Shopping',
      ];
      return allSections.some((section) => lifestyleSections.includes(section));
    },
  },
  {
    name: 'shoppy-bpage-test',
    variations: ['control', 'banner', 'chat'],
    storeResponse: true,
    isEligible: (buzz) => buzz.isShopping && buzz.isUS,
  },
  {
    name: 'SITE-7528-TPAU_with_images',
    variations: ['control', 'text', 'image'],
    isEligible: () => true,
    storeResponse: true,
  },
  {
    name: 'QZ-731-confetti_trivia',
    variations: [
      'control',
      'variant_no_confetti',
      'variant_result_confetti',
      'variant_both_confetti',
    ],
    storeResponse: true,
    //only US bfp_quiz subbuzzes without result carousel
    isEligible: buzz =>
      buzz.isUS &&
      buzz.isTrivia &&
      buzz.editorial_status !== 'Advertiser' &&
      buzz.bfpFormats.indexOf('quiz') !== -1 &&
      buzz.tags.indexOf('shopping-all-quiz-results') === -1,
  },
  {
    name: 'QZ-731-confetti_personality',
    variations: [
      'control',
      'variant_no_confetti',
      'variant_result_confetti',
      'variant_both_confetti',
    ],
    storeResponse: true,
    //only US bfp_quiz subbuzzes without result carousel
    isEligible: buzz =>
      buzz.isUS &&
      buzz.isPersonality &&
      buzz.editorial_status !== 'Advertiser' &&
      buzz.bfpFormats.indexOf('quiz') !== -1 &&
      buzz.tags.indexOf('shopping-all-quiz-results') === -1,
  },
  {
    name: 'PAR-436-etsy-tpau-ranking',
    variations: ['control', 'recsys'],
    isEligible: () => true,
  },
  {
    name: 'COMMERCE-1100_related_products_unit',
    variations: ['control', 'product_grid', 'product_carousel'],
    storeResponse: true,
    isEligible: buzz => buzz.isShopping && buzz.isUS,
  },
  {
    name: 'NEWS-448_storyly_integration',
    variations: ['control', 'storyly'],
    isEligible: buzz => isNews(buzz),
  },
  {
    name: 'ml-3019-related-content-nomic',
    variations: ['control', 'variant_1', 'variant_2'],
    isEligible: buzz => buzz.isUS,
  },
  {
    name: 'bfn-taboola-placement',
    variations: ['control', 'on'],
    storeResponse: true,
    isEligible: buzz => isNews(buzz),
  },
  {
    name: 'RT-583-amazon-recommendation-abtest',
    variations: ['control', 'on'],
    storeResponse: true,
    isEligible: buzz => buzz.isUS,
  },
  {
    name: 'ri_plushie_filter_login',
    variations: ['control','variant'],
    isEligible: buzz =>
      buzz.isUS &&
      getUserGeoCountry().toLowerCase() === 'us',
  },
  {
    name: 'RT-1257-Wunderkind_desktop_only',
    variations: ['control', 'no_wunderkind'],
    isEligible: buzz => getUserGeoCountry().toLowerCase() === 'us' &&
    !buzz.isShopping && !isNews(buzz) &&
    window.matchMedia('(min-width: 40rem)').matches,
  },
  {
    name: 'RT-946-shopping-recirc-bfn',
    variations: ['control', 'var1'],
    isEligible: buzz => isNews(buzz)
  },
  {
    name: 'ads_delay_fetch',
    variations: ['control', 'var1', 'var2', 'var3'],
    isEligible: buzz =>
      buzz.isUS &&
      getUserGeoCountry().toLowerCase() === 'us' &&
      !window.matchMedia('(min-width: 40rem)').matches, // xs,
  },
  {
    name: 'RT-947-remove-semisticky-bfn',
    variations: ['control', 'on'],
    isEligible: buzz =>
      isNews(buzz) &&
      window.matchMedia('(min-width: 40rem)').matches,
  },
  {
    name: 'RT-945-tb-size-bfn-desktop',
    variations: ['control', 'tb_728x90'],
    isEligible: (buzz) =>
      isNews(buzz) &&
      window.matchMedia('(min-width: 40rem)').matches,
  },
  {
    name: 'RT-1150-ButtonPostTap-abtest',
    variations: ['control', 'no_button_wrap'],
    storeResponse: true,
    isEligible: buzz => buzz.isUS &&
      buzz.destination_name === 'buzzfeed'
  },
  {
    name: 'ads_retail_media_network_exposure',
    variations: ['control', 'rmn_off'],
    storeResponse: true,
    isEligible: buzz => buzz.isShopping && !buzz.isAd && buzz.isUS,
  },
];

const combinedFlags = [CONSENT_FEATURE_FLAG, ...featureFlags, ...abTests];
export default combinedFlags;
