import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ABeagleContext from '../../contexts/abeagle';
import BuzzContext from '../../contexts/buzz';
import EXPERIMENTS from '../../constants/ab-tests';
import { useABeagleExperiments } from '@buzzfeed/react-components';
import {
  isOn,
  getExperimentVariant,
} from '@buzzfeed/react-components/lib/utils/abeagle';
import { abeagle_host } from '../../constants';
import { useUserId } from '../../hooks/useUserId';
import { trackClientExperimentActive } from '../../hooks/analytics/client-event-tracking';
import { getAdTests } from '@buzzfeed/adlib/dist/module/services/ab-tests/standalone';
import { getUserGeoCountry } from '@buzzfeed/bf-utils/lib/localization';
import { isServer } from '../../utils/isServer';
import { isNews } from '../../utils/isNews';
import { storeAbeagleResponses as storeAffiliateAbeagleResponses } from '@buzzfeed/react-components/lib/utils/affiliate';

function ABeagle({ children }) {
  /**
   * Inside hook, will fetch once userId is a defined value, otherwise will return
   *   default experiments object
   */
  const buzz = useContext(BuzzContext);
  const userId = useUserId();
  const returnedExperiments = useRef(null);
  const isBFN = isNews(buzz);

  // Ads experiments require additional page context for eligibility checks
  const AD_TESTS = getAdTests({
    isBPage: true,
    isNewBPage: true,
    isFeedPage: false,
    isBFO: buzz.destination_name === 'buzzfeed',
    isBFN,
    localizationCountry: buzz.country_code,
    userCountry: isServer() ? '' : getUserGeoCountry(),
    edition: buzz.country_code,
    isAdPost: () => buzz.isAd,
  });

  const experiments = useABeagleExperiments({
    abeagleHost: abeagle_host,
    data: buzz,
    experimentConfig: [...EXPERIMENTS, ...AD_TESTS],
    source: 'buzz_web',
    userId,
  });

  // keep track of buzz ID for which experiments were fetched
  if (
    !returnedExperiments.current ||
    returnedExperiments.current.loaded !== experiments.loaded
  ) {
    returnedExperiments.current = {
      ...experiments,
      buzzId: buzz.id,
    };
  }
  // mark previous experiments as stale while experiments for the new buzz are being fetched
  returnedExperiments.current.stale =
    returnedExperiments.current.buzzId !== buzz.id;

  const loaded = returnedExperiments.current.loaded;

  // quick memoize for eligible experiments
  // @todo - update react-components to return experiment state as memoized object
  const experimentKeys = experiments.eligible
    ? Object.keys(experiments.eligible).join('|')
    : '';

  // fire tracking when experiments or buzz ID has changed
  useEffect(() => {
    if (!loaded || !experimentKeys.length) {
      return;
    }

    const experiment_id = [];

    Object.keys(experiments.eligible).forEach(key => {
      const experiment = experiments.eligible[key];

      // Only send status if bucketed (has variant in 'value')
      if (experiment && experiment.value) {
        experiment_id.push(
          [
            key,
            experiment.id,
            experiment.version,
            experiment.value,
            experiment.variant_id,
          ].join('|')
        );

        storeAffiliateAbeagleResponses(EXPERIMENTS, key, experiment);
      }
    });

    // Fire one event for ALL eligible experiments (only one event should fire)
    trackClientExperimentActive(buzz, { experiment_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buzz, loaded, experimentKeys]);

  /**
   *
   * @type {Function}
   * @param {String} experimentName
   * @param {String} onValue
   */
  const getFeatureFlagValue = (experimentName, onValue = 'on') =>
    isOn(experiments, experimentName, onValue);

  const getFeatureFlagPayload = (experimentName, onValue = 'on') => {
    const isFeatureFlagOn = isOn(experiments, experimentName, onValue);
    if (isFeatureFlagOn && experiments?.returned?.[experimentName]) {
      const fullVariant = experiments.returned[experimentName];
      return fullVariant?.payload || null;
    }
    return null;
  };

  const getExperimentValue = (experimentName, options) =>
    getExperimentVariant(experiments, experimentName, options);

  return (
    <ABeagleContext.Provider
      value={{
        experiments: returnedExperiments.current,
        getFeatureFlagValue,
        getExperimentValue,
        getFeatureFlagPayload,
      }}
    >
      {children}
    </ABeagleContext.Provider>
  );
}

ABeagle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ABeagle;
